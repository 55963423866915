import FileUploadFlow from "./FileUploadFlow";
import PageRangeSelector from "./PageRangeSelector";
import { useEffect, useState } from "react";
import useFetchDownload from "../services/useFetchDownload.js";
import { useNavigate } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist/webpack.mjs";
import useUserName from "../services/useUserName";
import useTrackUserEvent from "../services/useTrackUserEvent";

const Step1 = ({ setStep1Download }) => {
  const instructionText = "Step 1: Upload Brief to Extract Answers";
  const [startPage, setStartPage] = useState("");
  const [endPage, setEndPage] = useState("");
  const [maxPage, setMaxPage] = useState();
  const [processing, setProcessing] = useState(false);
  const [complete, setComplete] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const [isValid, setIsValid] = useState(false);
  const fetchDownload = useFetchDownload();

  const user = useUserName();

  const trackUserEvent = useTrackUserEvent();

  useEffect(
    () => (document.title = "Podium Prepper - Step 1: Upload Brief"),
    []
  );
  useEffect(() => setStep1Download(null), [setStep1Download]);
  useEffect(() => {
    if (file && pdfjsLib.isPdfFile(file.path)) {
      var fileReader = new FileReader();

      fileReader.onload = function () {
        var typedarray = new Uint8Array(this.result);

        const loadingTask = pdfjsLib.getDocument(typedarray);
        loadingTask.promise
          .then((pdf) => {
            setStartPage(1);
            setEndPage(pdf.numPages);
            setMaxPage(pdf.numPages);
          })
          .catch((error) => {
            setError(file.name + " was not loadable as a pdf");
            setFile(null);
          });
      };
      fileReader.readAsArrayBuffer(file);
    }
  }, [file]);

  const navigate = useNavigate();

  const ConfirmAction = (file) => {
    setProcessing({
      estimatedTime: 15 + 0.5 * (parseInt(endPage) - parseInt(startPage)),
    });

    var formdata = new FormData();
    formdata.append("file", file);
    formdata.append("startPage", startPage);
    formdata.append("endPage", endPage);
    formdata.append("user", user);

    trackUserEvent("Submitted brief and page range for step 1", {
      file: file.name,
      startPage,
      endPage,
    });

    fetchDownload(formdata, process.env.REACT_APP_API_ENDPOINT_STEP_1)
      .then((download) => {
        setStep1Download(download);
        setComplete(true);
      })
      .catch((error) => {
        setProcessing(false);
        setFile(null);
        setStartPage("");
        setEndPage("");
        setIsValid(false);
        setError(error.message);
        console.log("error", error);
      });
  };

  return (
    <>
      <FileUploadFlow
        InstructionalText={instructionText}
        ConfirmContent={
          <PageRangeSelector
            {...{
              maxPage,
              startPage,
              setStartPage,
              endPage,
              setEndPage,
              setIsValid,
            }}
          />
        }
        FileTypes={[".pdf"]}
        ConfirmAction={ConfirmAction}
        ConfirmEnabled={isValid}
        onCancel={() => {
          setStartPage("");
          setEndPage("");
          setIsValid(false);
          trackUserEvent("Cancelled file selection for Step 1");
        }}
        onComplete={() => {
          console.log("navigating");
          navigate("/step2");
        }}
        {...{ processing, complete, error, setError, file, setFile }}
      />
    </>
  );
};

export default Step1;
